import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { tint } from "polished";

import config from "./config";
import shevy from "./typography";

const { h1, h2, h3, h4, h5, h6 } = shevy;

const heading = css`
    font-family: ${config.baseSansSerifFontFamily};
`;

const H1 = styled.h1`
  ${heading}

  font-size: ${h1.fontSize};
  line-height: ${h1.lineHeight};
  margin: 0 0 ${h1.marginBottom};

  color: ${tint(0.04, config.baseFontColour)};
  font-weight: 300;
  text-rendering: optimizeLegibility;
`;

const H2 = styled.h2`
  ${heading}

  font-size: ${h2.fontSize};
  line-height: ${h2.lineHeight};
  margin: 0 0 ${h2.marginBottom};

  color: ${tint(0.04, config.baseFontColour)};
  font-weight: 300;
  text-rendering: optimizeLegibility;
`;

const H3 = styled.h3`
  ${heading}

  font-size: ${h3.fontSize};
  line-height: ${h3.lineHeight};
  margin: 0 0 ${h3.marginBottom};

  color: ${tint(0.04, config.baseFontColour)};
  font-weight: 400;
  text-rendering: optimizeLegibility;
`;

const H4 = styled.h4`
  ${heading}

  font-size: ${h4.fontSize};
  line-height: ${h4.lineHeight};
  margin: 0 0 ${h4.marginBottom};

  color: ${tint(0.02, config.baseFontColour)};
  font-weight: 400;
`;

const H5 = styled.h5`
  ${heading}

  font-size: ${h5.fontSize};
  line-height: ${h5.lineHeight};
  margin: 0 0 ${h5.marginBottom};

  color: ${tint(0.02, config.baseFontColour)};
  font-weight: 400;
`;

const H6 = styled.h6`
  ${heading}

  font-size: ${h6.fontSize};
  line-height: ${h6.lineHeight};
  margin: 0 0 ${h6.marginBottom};

  color: ${tint(0.02, config.baseFontColour)};
  font-weight: 500;
  text-transform: uppercase;
`;

export { H1, H2, H3, H4, H5, H6 };
