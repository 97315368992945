module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":800}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"<Hash size=\"0.8em\" />"}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Ayu Dark","extensions":["ayu"]}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ian Lai","short_name":"Ian Lai","start_url":"/","background_color":"#F6F6F6","theme_color":"#D8345F","display":"minimal-ui","icon":"static/img/favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
