/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import config from "../styleguide/config";
import shevy from "../styleguide/typography";
import { H6 } from "../styleguide/heading";

const { baseSpacing, content } = shevy;

const formStyle = css`
    ${content}

    display: flex;
    flex-direction: column;
`;

const formItemStyle = css`
    display: flex;
    flex-direction: column;
`;

const labelStyle = css`
    margin-bottom: ${baseSpacing(0.5)};
`;

const inputStyle = css`
    ${content}

    padding: ${baseSpacing(0.5)};
`;

const submitStyle = css`
    ${content}

    background: rgba(66, 72, 116, 0.9);
    border: none;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    display: block;
    font-family: ${config.baseSansSerifFontFamily};
    margin: 0;
    outline: none;
    padding: ${baseSpacing(0.5)} ${baseSpacing(1)};

    &:hover {
        background: rgba(66, 72, 116, 0.8);
    }
`;

const ContactForm = () => {
    return (
        <form action="//formspree.io/os@fyianlai.com" method="POST">
            <div css={formStyle}>
                <div css={formItemStyle}>
                    <label css={labelStyle} htmlFor="name">
                        Name
                    </label>
                    <input
                        css={inputStyle}
                        id="name"
                        type="text"
                        name="name"
                        required
                    />
                </div>
                <div css={formItemStyle}>
                    <label css={labelStyle} htmlFor="email">
                        E-mail address
                    </label>
                    <input
                        css={inputStyle}
                        id="email"
                        type="email"
                        name="_replyto"
                        required
                    />
                </div>
                <div css={formItemStyle}>
                    <label css={labelStyle} htmlFor="message">
                        Message
                    </label>
                    <textarea
                        css={inputStyle}
                        id="message"
                        name="message"
                        rows="5"
                        required
                    ></textarea>
                </div>
                <div css={formItemStyle}>
                    <H6>
                        Your e-mail address will not be shared or saved by me.
                        Your message will be sent using Mailgun's API, and
                        hence, their privacy policy applies.
                    </H6>
                </div>
                <div css={formItemStyle}>
                    <div
                        css={css`
                            align-items: flex-start;
                        `}
                    >
                        <input
                            css={inputStyle}
                            type="text"
                            name="_gotcha"
                            style={{ display: "none" }}
                        />
                        <input
                            css={submitStyle}
                            type="submit"
                            value="Send Message"
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
