import Shevy from "shevyjs";
import { stripUnit } from "polished";

export const getPreciseLineHeight = (
    baseFontSizePx,
    fontSizeRem,
    lineHeight
) => {
    const preciseLineHeight =
        stripUnit(baseFontSizePx) *
        stripUnit(fontSizeRem) *
        stripUnit(lineHeight);
    return `${Math.round(preciseLineHeight)}px`;
};

const shevy = new Shevy({
    baseFontSize: "1rem",
    baseLineHeight: 1.5,
    // baseFontScale: [2.618, 2.118, 1.618, 1.309, 1, 0.618],
    baseFontScale: [2.618, 2.267, 1.618, 1.401, 1, 0.618],
});

export default shevy;
